@font-face {
  font-family: 'Haffer XH';
  src: url('assets/fonts/HafferXH-Regular.woff2') format('woff2'),
    url('assets/fonts/HafferXH-Regular.woff') format('woff'),
    url('assets/fonts/HafferXH-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Haffer XH';
  src: url('assets/fonts/HafferXH-SemiBold.woff2') format('woff2'),
    url('assets/fonts/HafferXH-SemiBold.woff') format('woff'),
    url('assets/fonts/HafferXH-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'VC Nudge';
  src: url('assets/fonts/VCNudgeRegularTrial.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: 'Haffer XH';
  font-size: 14px;
  height: 100%;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

ul,
ol {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

a {
  color: #4d4d4d;
  text-decoration: none;
}

a:active,
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
  color: #1d1d1b;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

:focus {
  outline: none;
}

img {
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.align-items-c {
  align-items: center;
}

.justify-content-sb {
  justify-content: space-between;
}

.ExtraLargeText {
  color: #1c1b18 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  font-family: 'Haffer XH' !important;
  font-weight: 600 !important;
}

.LargeText {
  font-size: 20px !important;
  line-height: 28px !important;
  font-family: 'Haffer XH' !important;
}

.MediumText {
  font-size: 18px !important;
  line-height: 24px !important;
  font-family: 'Haffer XH' !important;
  font-weight: 600 !important;
}

.SmallText {
  font-size: 16px !important;
  line-height: 22px !important;
  font-family: 'Haffer XH' !important;
}

.ExtraLargeText {
  font-size: 18px;
  line-height: 24px;
}

.LargeTitleText {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Haffer XH' !important;
  font-weight: normal !important;
}

.MediumTitleText {
  font-size: 14px !important;
  line-height: 20px !important;
  font-family: 'Haffer XH' !important;
}

.SmallTitleText {
  font-size: 12px !important;
  line-height: 16px !important;
  font-family: 'Haffer XH' !important;
}
.warning-property {
  color: #505255 !important;
  font-weight: 600 !important;
}
.para-advance-match {
  font-family: Haffer XH !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #505255 !important;
}
.parag-color {
  color: #252603 !important;
}
.customer-onboarding-title-color {
  color: #545048 !important;
}
.black-color {
  color: #000000 !important;
}
.login-sidebar {
  background: #314139 !important;
}
.title-color {
  color: #1c1b18 !important;
}

.word-break {
  word-break: break-all;
}

/*-----Login-page-css-------*/
.side-menu a.padding-left {
  padding-left: 30px !important;
}

.link-img {
  width: 12px;
}

.border-bottom {
  border-bottom: 1px solid #236cff;
}

.login-app-link a {
  font-size: 16px;
  color: #236cff;
}

.login-app-link {
  padding: 30px 0 30px 30px;
}

.login-box {
  background-color: #d2d4d8 !important;
  height: 100%;
}

.css-1a6wk4a {
  margin-bottom: 0;
}

.quickbooks-logo {
  margin-left: 30px;
  margin-left: 0;
  width: 280px;
}

.quickbooks-logo {
  margin-right: auto;
  padding-right: 25px;
}

.gocardless-logo {
  border-left: 1px solid #96938c;
  padding-left: 25px;
}

.login-left-img {
  margin: 0;
}

/* .login-page.MuiBox-root,
.MuiBox-root.css-1tlkuxi {
  height: 100%;
} */
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.css-m9a1j-MuiGrid-root {
  height: calc(100% - -9px);
}

.left-section-img {
  height: calc(100% - 0em);
  display: flex;
  justify-content: center;
}

.login-page {
  background: #fff;
  height: 100% !important;
}

.right-box {
  max-width: 600px;
  padding-left: 100px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.right-box .MuiBox-root.css-0 {
  height: auto;
  align-items: center;
}

.sign-in-text {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Haffer XH' !important;
  line-height: 22px;
  margin: 22px 0 5px 0;
  color: #27272e !important;
}

.parag-text {
  font-size: 16px !important;
  line-height: 22px !important;
  font-family: 'Haffer XH' !important;
  color: #425465 !important;
}

.btn-box {
  margin: 30px 0 0 0;
}

.sign-in-btn {
  display: block !important;
  margin-bottom: 30px !important;
  font-size: 18px !important;
  line-height: 22px !important;
  width: 50%;
  padding: 15px 0 !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-family: 'Haffer XH' !important;
}

.sign-in-blue-btn {
  background-color: transparent;
  padding: 0;
  width: auto;
  height: auto;
  margin-bottom: 30px;
}

.sign-in-intuit-blue-bnt {
  background-color: #0077c5 !important;
}

/* .sign-in-intuit-white-btn {
  color: #333 !important;
  background-color: #fff !important;
  border: 1px solid #D2D4D8 !important;
} */
#root {
  height: 100%;
}

.sign-in-intuit-black-btn {
  background-color: #1c1b18 !important;
}

.sign-in-btn img {
  padding-right: 10px;
}

.left-section-img img {
  width: 80%;
}

.sign-in-blue-btn {
  background-color: transparent !important;
  padding: 0 !important;
  width: auto !important;
  height: auto !important;
  margin-bottom: 30px !important;
}

/*------End-login-page-css------*/
/*----header-css----*/
header {
  background: #000000 !important;
  height: 64px !important;
  padding: 6px 24px !important;
  justify-content: center;
}

.header-box {
  height: 64px !important;
}

.profile-dropdown ul {
  padding: 0;
}

.side-menu .MuiList-root {
  padding: 0;
}

.side-menu a,
.side-menu .dropdown-btn {
  font-weight: 600 !important;
  padding: 12px 20px !important;
  font-size: 16px !important;
  font-family: Haffer XH !important;
  border-radius: 0;
  border-left: 4px solid transparent;
  color: #1c1b18 !important;
}

.side-menu a.active,
.side-menu a.hover,
.side-menu a.focus {
  border-color: #1c1b18 !important;
  background-color: #faf9f7 !important;
}

.side-menu {
  padding-top: 18px;
}

.sidebar-menu {
  width: 248px !important;
  min-width: 248px !important;
  padding-bottom: 0 !important;
  background-color: #fff !important;
  overflow-y: auto;
}

.side-menu .MuiList-padding {
  padding: 0 !important;
}
.gcl-nav {
  position: sticky;
  top: 0;
  z-index: 2;
}
.hide-pera {
  visibility: hidden !important;
}
.connected-app-logo {
  margin-top: 42px !important;
}
.connected-app-btn {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-2 {
  margin-top: 2px !important;
}
.m-26 {
  margin-top: 26px !important;
}
.mb-13 {
  margin-bottom: 13px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mb-18 {
  margin-bottom: 18px !important;
}
.m-8 {
  margin-top: 8px !important;
}
.mt-6 {
  margin-top: 6px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.automation {
  margin-bottom: -3px !important;
}
.connected-app-statusbar {
  font-family: Haffer XH;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px !important;
  letter-spacing: 0px !important;
  text-align: left;
  color: #1c1b18;
}
.login-sidebar-header {
  font-family: 'VC Nudge' !important;
  font-size: 54px !important;
  font-weight: 700 !important;
  line-height: 49px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #efece7 !important;
}
.login-sidebar-pera {
  font-family: Haffer XH;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #efece7;
}
.customer-onboarding-bg {
  background: #ffffff !important;
}
.gcl-sidebar {
  z-index: 1;
}
.radio-fieldset > label > div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff !important;
}
.manually-schedule {
  margin-top: -52px !important;
}
.mt-2 {
  margin-top: 2px !important;
}
.mt-4 {
  margin-top: 4px;
}
.mt-13 {
  margin-top: 13px !important;
}
.currency-header-color {
  color: #1e1a14;
}
.client-profile button {
  font-weight: 600 !important;
  padding: 9px 0px !important;
}

.sidebar-dropdown-menu {
  width: 100%;
}
.comapny-name-top {
  color: #efece7 !important;
}
.login-header-text {
  color: #2c2d2f;
}
.nbsp {
  padding-left: 7px !important;
}
.toggle-menu button svg {
  height: 2em !important;
  color: #fff !important;
}

.toggle-menu {
  margin-left: 0;
}
.img117px {
  height: 117px !important;
  width: 117px !important;
}

.toggle-menu {
  display: none !important;
}

.header-logo {
  margin-bottom: -8px;
}

.content-detail {
  margin-left: 248px !important;
  /* padding: 90px  30px 15px 30px !important; */
  background: #eae5df;
  height: 95vh;
}

.profile-dropdown .MuiPaper-root {
  margin-top: 11px;
  border-radius: 0px 0 5px 5px !important;
}

.profile-dropdown .MuiPaper-root li,
.profile-dropdown .MuiPaper-root a {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: normal !important;
  font-family: Haffer XH !important;
  color: #1c1b18 !important;
}

/* .get-support-box {
  position: fixed;
  bottom: 10px;
  left: 35px;
} */

.get-support-btn {
  background-color: #1c1b18 !important;
  border-radius: 31px !important;
  padding: 7px 22px;
  width: 176px;
  font-weight: 600 !important;
  font-family: Haffer XH !important;
}
.support-btn {
  position: absolute;
  bottom: 18px;
  left: 10px;
}
.toggle-menu button {
  background-color: transparent !important;
}

.css-11rgazn .MuiList-root .MuiListItem-root {
  padding: 0px !important;
}

/*---header-css-----*/
body {
  background: #eae5df !important;
}

/*-----page-css-----*/
.sub-dropdown {
  padding-left: 10px;
}

.side-menu .sub-dropdown-list a {
  padding: 12px 40px !important;
}

.title-text {
  line-height: 32px !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  font-family: 'Haffer XH' !important;
  color: #1c1b18 !important;
}

/*----End-page-css----*/
.side-menu {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  justify-content: space-between;
  padding-bottom: 0px;
}

.sub-menu-bottom {
  padding-bottom: 61px !important;
}

.get-support-box {
  margin: 10px auto;
  display: table;
}

.sub-menu-bottom a {
  letter-spacing: -0.04px;
  color: #7a828a !important;
  font-size: 14px !important;
  font-family: Haffer XH !important;
  font-weight: normal !important;
}

.content-detail > div > div {
  padding: 10px 30px 15px 30px !important;
}

.box-radius {
  border-radius: 8px !important;
}

.link-border {
  border-bottom: 1px solid #236cff;
  color: #236cff !important;
}

.select-input {
  height: 38px !important;
  /* border: 1px solid #8C8579 !important; */
  border-radius: 8px !important;
  margin-left: 0px !important;
  background-color: #fff !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #1c1b18 !important;
  border-width: 1px !important;
}

.select-input:focus {
  outline: none !important;
}

.css-1ddmygd-MuiSwitch-root
  .MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  opacity: 1 !important;
  background: #1e1a14 !important;
}

.checked-box .MuiFormControlLabel-label {
  font-family: 'Haffer XH' !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #1c1b18 !important;
  padding-left: 10px !important;
}

.checked-box {
  font-size: 25px !important;
  padding-left: 10px;
}

.checked-box > span {
  padding: 0px !important;
}

.checked-box .css-i4bv87-MuiSvgIcon-root {
  font-size: 36px !important;
  height: 31px !important;
  fill: #1c1b18 !important;
}

.menuitem-box li {
  font-size: 14px !important;
  line-height: 20px !important;
  color: #252603 !important;
  padding: 6px 11px;
}

.MuiMenu-list {
  padding: 0;
}

.btn {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
  font-family: 'Haffer XH' !important;
  border: 2px solid #1c1b18 !important;
  border-radius: 32px !important;
  height: auto;
  padding: 8px 30px !important;
}

.save-btn,
.copylink-btn {
  background: #1c1b18 !important;
  color: #fff;
}

.cancel-btn {
  color: #1c1b18 !important;
  background-color: transparent;
}

/* .content-detail > div > div > div > div {
  padding-bottom: 15px !important;
} */
.content-detail .MuiGrid-container {
  padding-bottom: 15px !important;
}

.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-16aq49t-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 14px !important;
  color: #1c1b18 !important;
  font-family: 'Haffer XH' !important;
  font-weight: normal !important;
}

.css-187kc9w-MuiList-root-MuiMenu-list .MuiMenuItem-root.MuiButtonBase-root {
  font-size: 14px !important;
  color: #1c1b18 !important;
  font-family: 'Haffer XH' !important;
  font-weight: normal !important;
  font-style: normal !important;
}

/*---Switch---*/
.css-1m3wd8f-MuiSwitch-track {
  height: 20px !important;
  border-radius: 30px !important;
  box-shadow: none !important;
  border-color: rgb(34 51 84 / 36%) !important;
}

.css-1ddmygd-MuiSwitch-root .MuiButtonBase-root {
  top: 9.3px;
  right: 24px;
}

.css-yj87sc-MuiButtonBase-root-MuiSwitch-switchBase:hover {
  background-color: transparent !important;
}

.css-1ddmygd-MuiSwitch-root {
  width: 63px !important;
  top: -4px !important;
}

.css-hqp9x7-MuiSwitch-thumb {
  width: 13px !important;
  height: 13px !important;
  margin-left: 5px;
  box-shadow: none !important;
}

.css-yj87sc-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  .MuiSwitch-thumb {
  background-color: #fff !important;
}

.css-h0vtzl-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 !important;
}

.cancel-btn:hover {
  background-color: transparent !important;
}

.border-bottom {
  border-bottom: 1px solid #dfddda;
}

.link-text {
  color: #252603 !important;
  font-weight: 600;
}

/*--Switch-----*/
/*-----automation-------*/
.input-label {
  font-family: 'Haffer XH' !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #1c1b18 !important;
  transform: inherit !important;
}

/* .input-box {
  margin-top: 17px !important;
} */
.input-box input,
.input-box textarea {
  border-radius: 8px !important;
  border-color: #8c8579 !important;
  height: 38px !important;
  padding: 0 10px !important;
  color: #1c1b18 !important;
  font-family: 'Haffer XH' !important;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 38px !important;
}

.input-box textarea {
  height: 140px !important;
  padding: 10px 10px !important;
}

input:focus,
.input-box textarea {
  box-shadow: none !important;
}

hr.css-ii6g1m-MuiDivider-root {
  background-color: #dfddda !important;
}

.copylink-btn img {
  padding-left: 5px !important;
}

.copylink-btn {
  margin-left: 40px !important;
}

/*------automation------*/

/*--------Reconciliation--------*/
.box-bg-color {
  background-color: #fff;
  border-radius: 24px;
}

/*-------Reconciliation---------*/

/*----------------*/
tr th {
  color: #6e685e !important;
  font-family: 'Haffer XH' !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  padding: 15px 20px !important;
  text-transform: capitalize !important;
  border-color: #dfddda !important;
}

tr td {
  font-family: 'Haffer XH' !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #1c1b18 !important;
  padding: 15px 20px !important;
  border-color: #dfddda !important;
}

.search-box {
  width: 40%;
}

.search-box input {
  padding: 0px 10px 0px 5px;
  height: 38px !important;
  line-height: 38px !important;
  color: #000 !important;
  font-family: 'Haffer XH' !important;
  font-weight: normal !important;
}

.css-io8h3s-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #000 !important;
  color: #fff !important;
}

.css-187kc9w-MuiList-root-MuiMenu-list
  .MuiMenuItem-root.MuiButtonBase-root.Mui-selected {
  background: rgb(0 0 0 / 11%) !important;
}

.css-187kc9w-MuiList-root-MuiMenu-list
  .MuiMenuItem-root.MuiButtonBase-root.Mui-selected {
  font-family: 'Haffer XH' !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

.pagination-box {
  padding: 15px 0;
}

/*----------------*/
.bg-white {
  background: #fff;
}

.css-yn29u0-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

.btn-img {
  padding: 0 !important;
  background-color: transparent !important;
  border: 0px !important;
}

.gocardless-logo-width {
  width: 150px !important;
}

/*--------dashboard-----------*/
.currency-name {
  font-weight: 600 !important;
  color: #1e1a14 !important;
  padding-left: 10px;
}

.collections-text {
  font-family: 'Haffer XH' !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #1c1b18 !important;
  padding-right: 2px;
}

.sub-text {
  color: #1c1b18 !important;
  font-family: 'Haffer XH';
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  border-bottom: 2px solid #eb7908;
  position: relative;
  top: -8px;
  margin-left: 3px;
}

.collected-box .sub-text {
  border-color: #12b378 !important;
}

.payout-box .sub-text {
  border-color: #4074db !important;
}
.report-text {
  font-family: 'Haffer XH' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #1c1b18 !important;
}
.processing-text {
  font-family: 'Haffer XH' !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #6e685e !important;
}
.reconciliation-per-graph {
  color: #1c1b18 !important;
}
.customer-onboarding-progress-color {
  color: #86878a;
}

.customer-onboarding-gray-title-color {
  color: #73767b !important;
}

.dashboard-table tr th {
  font-family: 'Haffer XH' !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #1c1b18 !important;
}

.dashboard-table .css-i4bv87-MuiSvgIcon-root {
  height: 15px;
  font-size: 18px;
}

.dashboard-table [data-testid='HelpOutlineOutlinedIcon'] {
  position: relative;
  top: 2px;
  color: #8c8579 !important;
}
.dashboard-table-tooltip svg {
  color: #8c8579 !important;
}
.plainLink-pending {
  border-bottom: 2px solid #eb7908 !important;
}
.plainLink-schedule {
  border-bottom: 2px solid #12b378 !important;
}
.plainLink-paid {
  border-bottom: 2px solid #4074db !important;
}

.css-12w4q21-MuiTableCell-root,
.css-843kzf-MuiTableCell-root,
.css-4co9jf-MuiTableCell-root,
.css-1rsnh6f-MuiTableCell-root {
  border-color: #dfddda !important;
}

.sub-title {
  color: #1c1b18 !important;
}

.css-bnwpjm-MuiTableRow-root,
.css-1ewq3p3-MuiTableCell-root,
.css-c35u50-MuiTableCell-root,
.css-n93fps-MuiGrid-root {
  background-color: #fff !important;
}

.css-16x51oj-MuiPaper-root-MuiTableContainer-root {
  border-radius: 8px !important;
}

.css-16x51oj-MuiPaper-root-MuiTableContainer-root,
.css-1rhfmfo-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

.icon-width {
  width: 24px;
}
.icon-color {
  color: #0d8559 !important;
}

.alerts-notifications-box {
  background: #ffffff;
  border-radius: 8px;
  height: auto;
  margin-top: 10px;
}

.notification-box {
  padding: 20px;
}

.notifications-text {
  font-family: 'Haffer XH' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #1e1a14 !important;
  padding-left: 15px;
}

.read-our-mainbox {
  padding: 20px;
  background-color: #faf9f7;
  border-radius: 8px;
  height: auto;
}

.read-our-box {
  padding: 20px;
  border: 1px solid #dfddda;
  background: #ffffff;
  border-radius: 8px;
}

.read-out-parag-text {
  color: #6e685e !important;
  padding-top: 6px;
}

/* .content-detail,
.content-detail > div,
.content-detail > div > div {
  height: 100%;
}
.content-detail {
  height: 100%;
} */
/* #root > div {
  height: 95.9%;
} */
.css-1d5kyig-MuiTypography-root {
  padding: 0 !important;
}

/*-------End-dashboard-----------*/

/*-----Customers----*/
.sync-status-btn {
  font-family: 'Haffer XH' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #1c1b18 !important;
}

.sync-status-btn img {
  padding-right: 5px;
}

.sync-status-btn:hover,
.sync-status-btn:focus {
  background-color: transparent !important;
}

/*----End-Customers-----*/
/*---modal-css---*/
.modal-box {
  border-radius: 24px;
  border: 0px !important;
}

.modal-title {
  font-family: 'Haffer XH' !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 38px !important;
  padding: 0px 30px 20px 30px !important;
}

.warning-box {
  background: #c83b3b;
  padding: 20px;
  color: #fff;
  font-size: 14px !important;
  line-height: 22px !important;
  font-family: 'Haffer XH' !important;
}
.warning-box-text {
  color: #505255 !important;
}

/*---End-modal-css--*/
.css-ukq1lc-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused.MuiOutlinedInput-notchedOutline,
.search-box fieldset {
  border-width: 1px !important;
}

.css-1glsrpm-MuiGrid-root,
.css-1mcgzbx-MuiInputBase-root .MuiInputBase-input,
.input-box textarea,
.css-17266hk-MuiTableCell-root,
.css-10ujv09-MuiTableCell-root {
  background-color: #fff !important;
}

.css-kjrv0u-MuiPaper-root {
  box-shadow: none !important;
}

.search-box > div > div {
  padding-top: 2px;
}

.customer-page .search-box {
  width: 298px !important;
}

.css-1vbcd4m-MuiInputAdornment-root {
  margin-left: 0 !important;
}

.page-sorting-box #filled-number {
  padding: 0px 5px 0 10px !important;
  height: 38px !important;
  line-height: 38px !important;
  width: 80px !important;
}

span.arrow-icon {
  display: inline-block;
  position: relative;
  top: 7px;
}
.refund-text {
  font-weight: 600;
}

.actions-btn button {
  padding: 0;
  color: #1c1b18;
}
.field-lable {
  color: #1c1b18;
}
.save-btn-color {
  color: #ffffff !important;
}
.css-90w0l8-MuiButtonBase-root-MuiButton-root:hover {
  background-color: transparent !important;
}

/*----tag----*/
.mandatestatus-tag {
  padding: 3px 5px;
  background: #ccc;
  border-radius: 4px;
  font-weight: 600;
}

.activation-filed,
.expired,
.cancelled {
  background: #f4cfcf !important;
  color: #b02a2a;
}

.active-tag {
  background: #d0f5e7;
  color: #0b6f4a;
}

.pending-activation {
  background: #ffe0c1;
  color: #a15000;
}

.submitted {
  background: #d4cbbe;
  color: #1c1b18;
}

/*----End-tag-css-----*/
.css-shvf88-MuiInputBase-root-MuiOutlinedInput-root {
  padding-left: 10px !important;
  border-radius: 8px !important;
}

.avilable-actions-title {
  font-weight: 600 !important;
  font-family: 'Haffer XH' !important;
  color: #1c1b18 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  padding-bottom: 7px;
}

.popover-Parag {
  padding: 0px 0 0px 0;
  color: #252603 !important;
}

/*-----popover------*/
.popover-box .MuiPopover-paper {
  padding: 20px !important;
}

.MuiInputBase-inputAdornedEnd {
  height: 38px !important;
  padding: 0 10px !important;
  line-height: 38px !important;
  color: #000 !important;
  font-family: 'Haffer XH' !important;
  font-weight: normal !important;
}

.popover-input .MuiInputBase-adornedEnd {
  padding-right: 0 !important;
  border-radius: 8px !important;
}

.send-btn {
  border-radius: 0 8px 8px 0 !important;
  background-color: #1c1b18 !important;
  font-family: 'Haffer XH' !important;
}

.popover-input {
  width: 100%;
}

.popover-input {
  width: 100%;
}

.email-invite-text {
  color: #1c1b18 !important;
  font-weight: 600 !important;
}

.popover-box li {
  padding: 0px 0px 0px 0px !important;
  margin-bottom: 10px;
}

.popover-box li:hover,
.popover-box li:focus,
.popover-box li:active {
  background-color: transparent !important;
}

.popover-input {
  margin: 15px 0 !important;
}

.sync-status-btn:hover,
.sync-status-btn:focus {
  background-color: transparent;
}

/*----End-popover----*/
.bottom-pagination-box {
  padding: 15px 20px;
  display: flex;
  align-items: center;
}

.bottom-pagination-box .MuiPagination-text {
  margin-right: auto;
}

.showing-box {
  margin-right: 25px;
}

.new-customers-modal .MuiDialog-paper {
  border-radius: 24px !important;
}

.modal-close-btn {
  font-size: 16px !important;
  color: #1c1b18 !important;
  padding: 0 !important;
  line-height: 22px !important;
}

.modal-close-btn:hover,
.modal-close-btn:focus {
  background-color: transparent !important;
}

.css-pjixsy-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
}

/*-----Copyright--------*/
.copyright-box {
  height: auto !important;
}

.copyright-box {
  height: auto !important;
  background: #eae5df !important;
  padding-bottom: 10px;
}

.copyright-box span {
  font-family: 'Haffer XH' !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 20px !important;
}

/*----End-Copyright-----*/
.MuiPopover-paper ul {
  padding: 0;
}

.MuiPopover-paper ul li {
  padding: 5px 10px !important;
  border-radius: 0 !important;
}

.MuiPopover-paper ul li:hover {
  background: rgb(227 227 227 / 43%) !important;
}

.goto-link-text {
  color: #252603 !important;
  border-bottom: 1px solid #252603;
}

.without-bg-btn:hover {
  background-color: transparent !important;
}

.my-footer {
  /* margin-top: 20px !important ; */
  padding-bottom: 16px !important ;
}

.main-box {
  margin-top: 20px !important;
}

.sidebar-support-button {
  position: fixed;
  margin-bottom: 14px !important;
}

.gcl-sidebar-box {
  padding-top: 0px !important;
}

.final > li:has(> a.active) {
  background-color: #faf9f7 !important;
}

.final li ul li:has(> a.active) {
  background-color: #faf9f7 !important;
}

.final li ul li ul li:has(> a.active) {
  background-color: #faf9f7 !important;
}
